import { IRpcRequest } from "@/interfaces/RPC"
import { defineStore } from "pinia"

export const useRpcStore = defineStore({
    id: "rpc",
    state: () => ({
        requests: [] as Array<IRpcRequest>,
        currentRequestId: "" as number | string
    }),
    getters: {
        getRequestsAmount(state) {
            return (state.requests && state.requests.length > 0) ? state.requests.length : 0
        },
        getLatestRequest(state) {
            return state.requests[state.requests.length - 1]
        }
    },
    actions: {
        async addRequest(request: IRpcRequest) {
            this.requests.push(request)
        },
        async setCurrentRequestId(id: number | string) {
            this.currentRequestId = id
        },
        async findRequest(id: number | string): Promise<IRpcRequest|null> {
            const index = this.requests.findIndex((request) => request.id === id)
            if (index >= 0)
                return this.requests[index]
            return null
        },
        async removeRequest(id: number | string) {
            const index = this.requests.findIndex((request) => request.id === id)
            if (index >= 0)
                this.requests.splice(index, 1)
            if (this.currentRequestId === id)
                this.currentRequestId = ""
        },
        async clearAllRequests() {
            this.requests = []
            this.currentRequestId = ""
        }
    }
})
