import { MigrationInterface, QueryRunner } from "typeorm"

export class CreateTransactionTable1698587772208 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            CREATE TABLE "transactions" (
                "Id"	     INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL,
                "Amount"	 INTEGER NOT NULL,
                "CoinsIds"	 TEXT NOT NULL,
                "CoinsVals"	 TEXT NOT NULL,
                "Currency"	 TEXT NOT NULL,
                "Date"	     INTEGER NOT NULL,
                "Incoming"	 BOOLEAN NOT NULL,
                "Type"	     INTEGER NOT NULL,
                "WalletId"	 TEXT NOT NULL,
                "WalletName" TEXT
            );
        `)
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            DROP TABLE "transactions";
        `)
    }

}
