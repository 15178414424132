
export enum ETransferMode {
    Offline,
    Online,
    P2P
}

export interface ITransferReceiver {
    WalletId: string
    WalletName: string,
    Summ: number
}

export interface ITransferExchangeRequest {
    coinId: string
    requiredValue: number
}

export interface ITransferSelectedCoinsResult {
    coinIds: Array<string>
    exchangeRequest: ITransferExchangeRequest
}

export interface ITransferLatetstReceivedTransactions {
    Amount: number,
    Summ: number,
    Currency: string
}
