import { MigrationInterface, QueryRunner } from "typeorm"

export class CreateWalletTable1698582737472 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            CREATE TABLE "wallets" (
                "Id"            TEXT PRIMARY KEY NOT NULL UNIQUE,
                "Name"          TEXT,
                "PublicKey"     TEXT NOT NULL,
                "EncPrivateKey" TEXT NOT NULL,
                "Type"          INTEGER NOT NULL
            );
        `)
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            DROP TABLE "wallets";
        `)
    }

}
