/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { toastController } from "@ionic/vue"
import i18n from "@/i18n"
import {
    informationOutline,
    closeOutline,
    checkmarkOutline,
    alertOutline
} from "ionicons/icons"

interface INotification {
    showNotification(text: string, type?: string, duration?: number): void
}

export class NotificationSimple implements INotification {
    showNotification(text: string, type?: string, duration?: number): void {
        alert(text)
    }
}

export class NotificationIonic implements INotification {

    async showNotification(text: string, type?: string, duration = 5000): Promise<void> {
        const id = `toast-${Math.trunc(Date.now() / 1000)}`
        const toast = await toastController
            .create({
                color: type,
                message: text,
                duration: duration,
                position: "top",
                cssClass: "custom",
                icon: (type === "danger")
                    ? closeOutline
                    : (type === "warning")
                        ? alertOutline
                        : (type === "success")
                            ? checkmarkOutline
                            : informationOutline,
                buttons: [
                    {
                        icon: "assets/icon/times.svg",
                        cssClass: id
                    }
                ]
            })
        await toast.present()

        const elem = toast.shadowRoot?.querySelector(`.${id}`)
        if (elem)
            elem.addEventListener("touchstart", () => {
                toast.dismiss()
            }, { once: true })
    }

    async showNotificationWithButtons(text: string, buttonText?: string, buttonFunc?: any, type?: string, duration = 10000): Promise<void> {
        const toast = await toastController
            .create({
                color: type,
                message: text,
                duration: duration,
                position: "top",
                cssClass: "custom is-buttons",
                layout: "stacked",
                icon: (type === "danger")
                    ? closeOutline
                    : (type === "warning")
                        ? alertOutline
                        : (type === "success")
                            ? checkmarkOutline
                            : informationOutline,
                buttons: [
                    {
                        text: (buttonText) ? buttonText : i18n.global.t("Interface.Button.Ok"),
                        handler: (buttonFunc) ? buttonFunc : () => { console.log("") }
                    },
                    {
                        text: i18n.global.t("Interface.Button.Cancel")
                    }
                ]
            })
        await toast.present()
    }
}
