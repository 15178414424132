import { useDB } from "@/composables/useDB"
import { Coin } from "@/models/coin"
import { ECoinCheckType, ICoin, ICoinVal } from "@/models/iCoin"
import { EWalletType, IWallet } from "@/models/iWallet"
import { Wallet } from "@/models/wallet"
import { defineStore } from "pinia"
import { ComponentInternalInstance } from "vue"
const { saveDbToWebStore } = useDB()

export interface ICoinsCheckedResult {
    checked: boolean,
    checkedResult: boolean,
    checkedOnline: boolean,
    checkedButNotSuccessAmount: number
}

export function chunkCoins(coins: Array<any>, size?: number): Array<any> {
    const chunkSize = (size) ? size : parseInt(<string>process.env.VUE_APP_CHECK_COINS_LIMIT, 10)
    const chunckedCoins = [] as Array<any>
    if (coins.length <= chunkSize) {
        chunckedCoins.push(coins)
    }
    else {
        for (let i = 0; i < coins.length; i += chunkSize) {
            chunckedCoins.push(coins.slice(i, i + chunkSize))
        }
    }
    return chunckedCoins
}

export const useWalletStore = defineStore({
    id: "wallet",
    state: () => ({
        wallets: [] as Array<Wallet>
    }),
    getters: {
        getWalletsAmount(state): number {
            return (state.wallets && state.wallets.length > 0) ? state.wallets.length : 0
        },
        getDefaultWallet(state): Wallet {
            return state.wallets.find((wallet) => wallet.Type === EWalletType.Default) as Wallet
        },
        getBufferWallet(state): Wallet {
            return state.wallets.find((wallet) => wallet.Type === EWalletType.Buffer) as Wallet
        },
        getWalletById: () => {
            return async(walletId: string) => {
                const wallet = await Wallet.findOne({ where: { Id: walletId } })
                return wallet as Wallet
            }
        },
        getWalletBalanceById: state => {
            return (walletId: string): number => {
                const wallet = state.wallets.find((wallet) => wallet.Id === walletId)
                if (!wallet)
                    return 0

                const result = wallet?.Coins.reduce((accumulator, { Val }) => accumulator + Val, 0)

                return (typeof result === "number") ? result : 0
            }
        },
        // getAsyncWalletBalanceById: () => {
        //     return async(walletId: string): Promise<number> => {
        //         const result = await Coin
        //             .createQueryBuilder()
        //             .select("SUM(coin.Val)", "sum")
        //             .where("coin.WalletId = :walletId", { walletId: walletId })
        //             .getRawOne()

        //         return (result && result.sum && typeof result.sum === "number") ? result.sum : 0
        //     }
        // },
        getAsyncSortedCoinsByWalletId: () => {
            return async(walletId: string): Promise<ICoinVal[]> => {
                const result = await Coin
                    .createQueryBuilder()
                    .select("coin.Id as id, coin.Val as value")
                    .where("coin.WalletId = :walletId", { walletId: walletId })
                    .orderBy("coin.Val", "DESC")
                    .getRawMany()

                return result
            }
        },
        getAsyncMaxAvailableSummForCoinsAmount: () => {
            return async(walletId: string, coinsAmount: number): Promise<number> => {
                const result = await Coin
                    .query(`
                        SELECT sum(Val) as max
                        FROM
                        (SELECT *
                        FROM coins
                        WHERE WalletId = "${walletId}"
                        ORDER BY Val DESC
                        LIMIT ${coinsAmount})
                    `)
                return result[0].max
            }
        },
        getGrouppedCoinsValsByWalletId: (state) => {
            return (walletId: string): Map<number, number> | null => {
                const indexWallet = state.wallets.findIndex((wallet) => wallet.Id === walletId)
                if (indexWallet < 0)
                    return null

                const coins = state.wallets[indexWallet].Coins
                if (!coins || coins.length < 1)
                    return null

                const map = new Map()
                const sortedCoins = state.wallets[indexWallet].Coins.sort((a: Coin, b: Coin) => { return b.Val - a.Val })
                sortedCoins.forEach((item: Coin) => {
                    const key = item.Val
                    const collection = map.get(key)
                    if (!collection)
                        map.set(key, 1)
                    else
                        map.set(key, collection + 1)
                })
                return map
            }
        },
        getCoinsByWalletIdAndVal: (state) => {
            return (walletId: string, val: number): Coin[] | null => {
                const indexWallet = state.wallets.findIndex((wallet) => wallet.Id === walletId)
                if (indexWallet < 0)
                    return null

                const coins = state.wallets[indexWallet].Coins
                if (!coins || coins.length < 1)
                    return null

                const coinsByVal = coins.filter((coin) => coin.Val === val)

                return coinsByVal
            }
        },
        getCoinsCheckedByWalletId: (state) => {
            return (walletId: string): ICoinsCheckedResult => {
                const result = {
                    checked: false,
                    checkedResult: false,
                    checkedOnline: false,
                    checkedButNotSuccessAmount: 0
                } as ICoinsCheckedResult

                const indexWallet = state.wallets.findIndex((wallet) => wallet.Id === walletId)
                if (indexWallet < 0)
                    return result

                const coins = state.wallets[indexWallet].Coins
                if (!coins || coins.length < 1)
                    return result

                const notCheckedCoins = coins.filter(coin => coin.Checked !== true)
                const notSuccessfullyCheckedCoins = coins.filter(coin => coin.CheckResult !== true)
                const notOnlineCheckedCoins = coins.filter(coin => coin.CheckType !== ECoinCheckType.Online)
                const checkedButNotSuccessfullyCheckedCoins = coins.filter(coin => (coin.Checked === true && coin.CheckResult !== true))
                result.checked = !(notCheckedCoins.length > 0)
                result.checkedResult = !(notSuccessfullyCheckedCoins.length > 0)
                result.checkedOnline = !(notOnlineCheckedCoins.length > 0)
                result.checkedButNotSuccessAmount = checkedButNotSuccessfullyCheckedCoins.length

                return result
            }
        },
        getCoinById: () => {
            return async(coinId: string): Promise<Coin | null> => {
                const result = await Coin.findOne({
                    where: { Id: coinId }
                })

                return result
            }
        },
        getCoinsByIds: (state) => {
            return (walletId: string, coinsIds: Array<string>): Coin[] | null => {
                const indexWallet = state.wallets.findIndex((wallet) => wallet.Id === walletId)
                if (indexWallet < 0)
                    return null

                const coins = state.wallets[indexWallet].Coins
                if (!coins || coins.length < 1)
                    return null

                const resultCoins = [] as Array<Coin>
                coinsIds.forEach(coinId => {
                    resultCoins.push(coins.find((coin) => coin.Id === coinId) as Coin)
                })
                return resultCoins
            }
        },
        getUncheckedOrRandomCoinsToCheckByWalletId: (state) => {
            return (walletId: string): Array<Coin[]> | null => {
                const indexWallet = state.wallets.findIndex((wallet) => wallet.Id === walletId)
                if (indexWallet < 0)
                    return null

                const coins = state.wallets[indexWallet].Coins
                if (!coins || coins.length < 1)
                    return null

                // Return not checked or unsuccessfully checked or offline checked coins
                const notCheckedCoins = coins.filter(coin => (coin.Checked !== true || coin.CheckResult !== true || coin.CheckType !== ECoinCheckType.Online))
                if (notCheckedCoins && notCheckedCoins.length > 0)
                    return chunkCoins(notCheckedCoins)

                // Return random coins
                const percent = (process.env.VUE_APP_CHECK_RANDOM_PERCENT) ? parseInt(process.env.VUE_APP_CHECK_RANDOM_PERCENT, 10) : 1
                const coinsToCheckAmount = Math.ceil(coins.length * percent / 100)
                const shuffledCoinsToCheck = coins.sort(() => 0.5 - Math.random())
                const coinsToCheck = shuffledCoinsToCheck.slice(0, coinsToCheckAmount)
                if (coinsToCheck && coinsToCheck.length > 0)
                    return chunkCoins(coinsToCheck)

                return null
            }
        }
    },
    actions: {
        async fill() {
            try {
                const dbWallets = await Wallet.find()
                this.wallets = dbWallets
            }
            catch (error) {
                console.log("Wallet store | Error in filling wallet store", error)
            }
        },
        async set(wallet: IWallet, appInstance: ComponentInternalInstance) {
            try {
                const newWallet = Wallet.new(wallet)
                newWallet.Coins = []
                this.wallets.push(newWallet)
                await newWallet.save()
                await saveDbToWebStore(appInstance)
            }
            catch (error) {
                console.log("Wallet store | Error in setting wallet info", error)
            }
        },
        async editWalletName(walletId: string, name: string, appInstance: ComponentInternalInstance) {
            try {
                const index = this.wallets.findIndex((storedWallet) => storedWallet.Id === walletId)
                if (index < 0)
                    throw new Error("Wallet not found for setting name")
                else {
                    this.wallets[index].Name = name
                    await this.wallets[index].save()
                    await saveDbToWebStore(appInstance)
                }
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        },
        async editWalletEncPrivateKey(walletId: string, encPvKey: string, appInstance: ComponentInternalInstance) {
            try {
                const index = this.wallets.findIndex((storedWallet) => storedWallet.Id === walletId)
                if (index < 0)
                    throw new Error("Wallet not found for setting encoded private key")
                else {
                    this.wallets[index].EncPrivateKey = encPvKey
                    await this.wallets[index].save()
                    await saveDbToWebStore(appInstance)
                }
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        },
        async addCoinsToWallet(coins: Array<ICoin>, walletId: string, appInstance: ComponentInternalInstance): Promise<Coin[]> {
            try {
                const indexWallet = this.wallets.findIndex((storedWallet) => storedWallet.Id === walletId)
                if (indexWallet < 0)
                    throw new Error("Wallet not found for adding coins")
                else {
                    const wallet = Wallet.new({
                        Id: this.wallets[indexWallet].Id,
                        Name: this.wallets[indexWallet].Name,
                        PublicKey: this.wallets[indexWallet].PublicKey,
                        EncPrivateKey: this.wallets[indexWallet].EncPrivateKey,
                        Type: this.wallets[indexWallet].Type
                    })
                    const addedCoins = coins.map(coin => Coin.new(coin, wallet))
                    this.wallets[indexWallet].Coins.push(...addedCoins)

                    await Coin.save(addedCoins)
                    await saveDbToWebStore(appInstance)

                    return addedCoins
                }
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
                return []
            }
        },
        async moveCoinsToWallet(coins: Array<Coin>, sourceWalletId: string, targetWalletId: string, appInstance: ComponentInternalInstance) {
            try {
                const indexTargetWallet = this.wallets.findIndex((storedWallet) => storedWallet.Id === targetWalletId)
                const wallet = Wallet.new({
                    Id: targetWalletId,
                    Name: this.wallets[indexTargetWallet].Name,
                    PublicKey: this.wallets[indexTargetWallet].PublicKey,
                    EncPrivateKey: this.wallets[indexTargetWallet].EncPrivateKey,
                    Type: this.wallets[indexTargetWallet].Type
                })
                for (const coin of coins) {
                    await Coin.update({ Id: coin.Id }, { Wallet: wallet })
                }
                await saveDbToWebStore(appInstance)
                await this.fill()
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        },
        async deleteCoinsFromWallet(coinsId: Array<string>, appInstance: ComponentInternalInstance) {
            try {
                await Coin.delete(coinsId)
                await saveDbToWebStore(appInstance)

                await this.fill()
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        },
        async deleteCoin(coinId: string, appInstance: ComponentInternalInstance) {
            try {
                this.wallets.forEach(async wallet => {
                    // Delete coin from source store wallet
                    wallet.Coins = wallet.Coins.filter((storedCoin) => storedCoin.Id !== coinId)
                })
                // Delete coin from DB
                await Coin.delete(coinId)
                await saveDbToWebStore(appInstance)
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        },
        async updateCoin(coin: Coin, appInstance: ComponentInternalInstance) {
            try {
                let findedCoinIndex = -1
                let findedWalletIndex = -1
                this.wallets.some(wallet => {
                    findedWalletIndex = findedWalletIndex + 1
                    findedCoinIndex = wallet.Coins.findIndex((storedCoin) => storedCoin.Id === coin.Id)
                    return findedCoinIndex >= 0
                })

                this.wallets[findedWalletIndex].Coins[findedCoinIndex] = coin
                await coin.save()
                await saveDbToWebStore(appInstance)
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        },
        async updateCoins(coins: Coin[], appInstance: ComponentInternalInstance) {
            try {
                await Coin.save(coins)
                await saveDbToWebStore(appInstance)

                await this.fill()
            }
            catch (error) {
                console.log(`Wallet store | ${error}`)
            }
        }
    }
})
