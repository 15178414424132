import dotnet, { DigitalCoinLib } from "@goznak-digital-currency/digital-wallet-lib"
import { ITransferSelectedCoinsResult } from "../interfaces/Transfer"
import { ICoin } from "../models/iCoin"
import { IWalletPreparedKeyPair } from "../models/iWallet"
import { objectLowerCaseKeys, objectUpperCaseKeys } from "../utils/Helpers"
import i18n from "@/i18n"

export function useDotnet() {
    const dotnetBoot = async(): Promise<boolean> => {
        try {
            dotnet.DigitalWallet_Lib.getHostName = () => "Vue.js"
            await dotnet.boot()
            return true
        }
        catch (err) {
            console.error(err)
            return false
        }
    }

    const dotnetGetBootStatus = (): dotnet.BootStatus => {
        try {
            return dotnet.getBootStatus()
        }
        catch (error) {
            console.error(`Cannot get dotnet boot status: ${error}`)
            throw new Error(i18n.global.t("Composables.UseDotnet.Errors.Error_1",{
                error: error
            }))
        }
    }

    const dotnetGeneratePreparedKeys = (password: string): IWalletPreparedKeyPair => {
        try {
            const { publicKey, privateKey } = dotnet.DigitalWallet_Lib.getKey()
            const encPrivateKey = dotnet.DigitalWallet_Lib.encrypt(privateKey, password)
            return { publicKey, encPrivateKey }
        }
        catch (error) {
            // Handle the error here, or re-throw it to be handled further up the call stack
            console.error(`Cannot generate prepared keys: ${error}`)
            throw new Error(i18n.global.t("Composables.UseDotnet.Errors.Error_2",{
                error: error
            }))
        }
    }

    const dotnetEncryptWalletPrivateKey = async(pvKey: string, password: string): Promise<string> => {
        try {
            const encryptedKey = dotnet.DigitalWallet_Lib.encrypt(pvKey, password)
            return encryptedKey
        }
        catch (error) {
            console.error(`Cannot encrypt wallet private key: ${error}`)
            throw i18n.global.t("Composables.UseDotnet.Messages.Message_1")
        }
    }

    const dotnetDecryptWalletPrivateKey = async(encPrivateKey: string, password: string): Promise<any> => {
        try {
            const privateKey = dotnet.DigitalWallet_Lib.decrypt(encPrivateKey, password)
            return privateKey
        }
        catch (error) {
            console.log(`Cannot decrypt wallet private key: ${error}`)
            throw i18n.global.t("Composables.UseDotnet.Messages.Message_2")
        }
    }

    const dotnetSignCoinToSend = async(coin: ICoin, encPrivateKey: string, password: string, receiverWalletId: string): Promise<ICoin> => {
        try {
            const pvKey = dotnet.DigitalWallet_Lib.decrypt(encPrivateKey, password)
            const value = dotnet.DigitalWallet_Lib.transferCoin(objectLowerCaseKeys(coin), receiverWalletId, pvKey)
            return objectUpperCaseKeys(value) as ICoin
        }
        catch (error) {
            console.error(`Cannot sign coin to send: ${error}`)
            throw new Error(i18n.global.t("Composables.UseDotnet.Errors.Error_3"))
        }
    }

    /**
     * Validates the signature of a coin object against a bank public key.
     * @param coin - The coin object to validate.
     * @param bankPublicKey - The public key of the bank that issued the coin.
     * @returns True if the signature is valid, false otherwise.
     */
    const dotnetCheckCoinSignatureOffline = (coin: ICoin): boolean => {
        try {
            const cbPublicKey = process.env.VUE_APP_CBANK_PUBLIC_KEY as string
            // Validate the signature using the DigitalWallet_Lib package.
            return dotnet.DigitalWallet_Lib.validateLastBankSignature(
                objectLowerCaseKeys(coin),
                cbPublicKey
            )
        }
        catch (error) {
            console.error(`Cannot check coin signature offline: ${error}`)
            throw new Error(i18n.global.t("Composables.UseDotnet.Errors.Error_4"))
        }
    }

    const dotnetSelectMultipleCoinsForTransfer = (coins: DigitalCoinLib.Exchange.CoinVal[], requiredAmount: number): ITransferSelectedCoinsResult => {
        try {
            return dotnet.DigitalWallet_Lib.selectCoins(coins, requiredAmount)
        }
        catch (error) {
            // Handle the error here e.g. log it or throw a custom exception
            console.error(`Unable to select coins for transfer: ${error}`)
            throw new Error(i18n.global.t("Composables.UseDotnet.Errors.Error_6") + error)
        }
    }


    const getSignatureForProxyAPI = (encPrivateKey: string, password: string, salt: number): string => {
        try {
            const pvKey = dotnet.DigitalWallet_Lib.decrypt(encPrivateKey, password)
            return dotnet.DigitalWallet_Lib.generateSignatureForRequestToProxy(pvKey, salt)
        }
        catch (error) {
            console.log(`Unable to get signature for proxy: ${error}`)
            throw i18n.global.t("Composables.UseDotnet.Errors.Error_5")
        }
    }


    return {
        dotnetBoot,
        dotnetGetBootStatus,
        dotnetGeneratePreparedKeys,
        dotnetDecryptWalletPrivateKey,
        dotnetEncryptWalletPrivateKey,
        dotnetSignCoinToSend,
        dotnetCheckCoinSignatureOffline,
        dotnetSelectMultipleCoinsForTransfer,
        getSignatureForProxyAPI
    }
}
