// VUE APP
import { IonicVue } from "@ionic/vue"
import { createPinia } from "pinia"
import { DisableSwipeBackDirective } from "v-disable-swipe-back"
import { createApp } from "vue"
import App from "./App.vue"
import i18n from "./i18n"
import router from "./router"

// VENDORS
import { FirebaseAnalytics } from "@capacitor-firebase/analytics"
import { App as IonicApp, URLOpenListenerEvent } from "@capacitor/app"

// STYLES
import "./styles.scss"

// SQLite dependencies
import sqliteConnection from "@/database"
import { CapacitorSQLite, SQLiteConnection } from "@capacitor-community/sqlite"
import { Capacitor } from "@capacitor/core"
import { SplashScreen } from "@capacitor/splash-screen"
import { applyPolyfills, defineCustomElements as jeepSqlite } from "jeep-sqlite/loader"
import "reflect-metadata"
import MainDataSource from "./data-source"

// RUN AT STARTUP
import { useBugfender } from "@/composables/useBugfender"
import { useDeeplink } from "@/composables/useDeeplink"
import { useDotnet } from "@/composables/useDotnet"
import { useRpcStore } from "@/stores/rpc"

// ------------------------------------------------------------------------------------------------

const deeplink = useDeeplink()
const { dotnetBoot } = useDotnet()
const { bfInit } = useBugfender()
const platform = Capacitor.getPlatform()
const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite)

// Polyfill to Web
applyPolyfills().then(() => {
    jeepSqlite(window)
})

// Check incoming Deep Link request when App isn't loaded yet
async function checkAppLaunchUrl() {
    const result = await IonicApp.getLaunchUrl()
    if (result && typeof result === "object" && result.url)
        await deeplink.init(result.url)
}

window.addEventListener("DOMContentLoaded", async() => {
    // BUILD
    const app = createApp(App)
        .use(router)
        .use(i18n)
        .use(IonicVue)
        .use(createPinia())
        .directive("disable-swipe-back", DisableSwipeBackDirective)

    /* SQLite Global Variables*/
    app.config.globalProperties.$platform = platform
    app.config.globalProperties.$sqlite = sqliteConnection

    try {
        if (platform === "web") {
            const jeepEl = document.querySelector("jeep-sqlite")
            if (jeepEl != null) {
                document.body.removeChild(jeepEl)
            }
            // Create the 'jeep-sqlite' Stencil component
            const jeepSqlite = document.createElement("jeep-sqlite")
            document.body.appendChild(jeepSqlite)
            await customElements.whenDefined("jeep-sqlite")
            // Initialize the Web store
            await sqliteConnection.initWebStore()
            await sqlite.initWebStore()
        }

        // when using Capacitor, you might want to close existing connections,
        // otherwise new connections will fail when using dev-live-reload
        // see https://github.com/capacitor-community/sqlite/issues/106
        CapacitorSQLite.checkConnectionsConsistency({
            dbNames: ["db-main"] // i.e. "i expect no connections to be open"
        }).catch((e) => {
        // the plugin throws an error when closing connections. we can ignore
        // that since it is expected behaviour
            console.log(e)
            return {}
        })

        if (!MainDataSource.isInitialized) {
            await MainDataSource.initialize()
        }
        await MainDataSource.runMigrations()

        if (platform === "web") {
            // save the database from memory to store
            await sqliteConnection.saveToStore("db-main")
            // await sqliteConnection.saveToLocalDisk("db-main")
        }

        // LAUNCH
        router.isReady().then(async() => {
            await checkAppLaunchUrl()
            app.mount("#app")
            await dotnetBoot()
            if (process.env.NODE_ENV === "production")
                await bfInit()
            if (platform !== "web" && platform !== "ios" && process.env.NODE_ENV === "production")
                await FirebaseAnalytics.setEnabled({ enabled: true })
            await SplashScreen.hide()
        })

        // Check incoming Deep Link request when App is already running
        IonicApp.addListener("appUrlOpen", async function(event: URLOpenListenerEvent) {
            const rpcStore = useRpcStore()
            console.log ("appUrlOpen: ", event)

            await deeplink.init(event.url)
            const rpcRequestsAmount = rpcStore.getRequestsAmount
            if (rpcRequestsAmount > 0) {
                const req = rpcStore.getLatestRequest
                await rpcStore.setCurrentRequestId(req.id)
                await deeplink.proceedRequest(req)
            }
        })
    }
    catch (err) {
        console.log(`Error: ${err}`)
        throw new Error(`Error: ${err}`)
    }
})
