/*
    Helpers library by Anton Zolotov (antonzolotov@outlook.com)
*/

export function getCurrentLanguage(): string {
    if (window.Intl && typeof window.Intl === "object") {
        // console.log(window.Intl.DateTimeFormat().resolvedOptions().locale)
        return window.Intl.DateTimeFormat().resolvedOptions().locale.slice(0, 2)
    }
    return process.env.VUE_APP_I18N_LOCALE ? process.env.VUE_APP_I18N_LOCALE : "en"
}

export function objectLowerCaseKeys(obj: any): any {
    if (typeof obj !== "object") {
        return obj
    }
    if (Array.isArray(obj)) {
        return obj.map(objectLowerCaseKeys)
    }
    if (obj === null) {
        return null
    }
    const entries = Object.entries(obj)
    const mappedEntries = entries.map(
        ([k, v]) => [
            `${k.substr(0, 1).toLowerCase()}${k.substr(1)}`,
            objectLowerCaseKeys(v)] as const
    )
    return Object.fromEntries(mappedEntries)
}

export function objectUpperCaseKeys(obj: any): any {
    if (typeof obj !== "object") {
        return obj
    }
    if (Array.isArray(obj)) {
        return obj.map(objectUpperCaseKeys)
    }
    if (obj === null) {
        return null
    }
    const entries = Object.entries(obj)
    const mappedEntries = entries.map(
        ([k, v]) => [
            `${k.substr(0, 1).toUpperCase()}${k.substr(1)}`, //TODO: Убрано особое условие для cbid
            objectUpperCaseKeys(v)] as const
    )
    return Object.fromEntries(mappedEntries)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function once(fn: Function) {
    let called = false
    return function(this: any, ...args: any) {
        if (called) return
        called = true
        return fn.apply(this, args)
    }
}
