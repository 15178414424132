import { defineStore } from "pinia"
import { INetwork } from "@/interfaces/Network"
import { NotificationIonic } from "@/utils/Notification"
const Notification = new NotificationIonic()

export const useNetworkStore = defineStore({
    id: "network",
    state: () => ({
        status: {
            connected: false,
            connectionType: "unknown"
        } as INetwork
    }),
    getters: {
        getConnectionStatus: (state) => state.status.connected,
        getConnectionType: (state) => state.status.connectionType
    },
    actions: {
        async setStatus(status: INetwork) {
            if (this.status.connected && !status.connected)
                Notification.showNotification("Включён офлайн режим работы", "warning")
            this.status = status
        }
    }
})
