export interface IWallet {
    Id: string
    Name: string
    PublicKey: string
    EncPrivateKey: string
    Type: EWalletType
}

export interface IWalletPreparedKeyPair {
    publicKey: string
    encPrivateKey: string
}
export enum EWalletType {
    Default = 0,
    Buffer = 1
}
