import { BaseEntity, Column, CreateDateColumn, Entity, ManyToOne, PrimaryColumn, UpdateDateColumn } from "typeorm"
import { ECoinCheckOnlineError, ECoinCheckType, ICoin } from "./iCoin"
import { Wallet } from "./wallet"

@Entity("coins")
export class Coin extends BaseEntity {

    // Main coin's fields
    @PrimaryColumn({ unique: true, type: "text" })
        Id!: string

    @Column({ type: "boolean" })
        Act!: boolean

    @Column({ type: "text" })
        CbId!: string

    @Column({ type: "text" })
        Cur!: string

    @Column({ type: "integer" })
        Exp!: number

    @Column({ type: "integer" })
        Issued!: number

    @Column({ type: "integer" })
        Tag!: number

    @Column({ type: "integer" })
        Val!: number

    @Column({ type: "text" })
        CryptoAlg!: string

    @Column({ type: "text" })
        CryptoCentbSalt!: string

    @Column({ type: "text" })
        CryptoCentbSign!: string

    @Column({ type: "text" })
        CryptoIssSalt!: string

    @Column({ type: "text" })
        CryptoIssSign!: string

    @Column({ type: "text" })
        Chain!: string

    // Additional coin's fields only for internal App usage
    @Column({ type: "integer" })
        ChainLastDate!: number

    @Column({ nullable: true, type: "text" })
        ChainLastFrom!: string

    @Column({ nullable: true, type: "text" })
        ChainLastSign!: string

    @Column({ type: "text" })
        ChainLastTo!: string

    @Column({ type: "integer" })
        ChainLinksAmount!: number

    @Column({ type: "boolean" })
        Checked!: boolean

    @Column({ type: "integer" })
        CheckDate!: number

    @Column({ type: "integer" })
        CheckType!: ECoinCheckType

    @Column({ nullable: true, type: "integer" })
        CheckOnlineError!: ECoinCheckOnlineError | null

    @Column({ type: "boolean" })
        CheckResult!: boolean

    @CreateDateColumn({ type: "date" })
        Receipted!: Date

    @UpdateDateColumn({ nullable: true, type: "date" })
        Updated!: Date

    @ManyToOne(() => Wallet, (wallet) => wallet.Coins, { cascade: ["insert"] })
        Wallet!: Wallet


    static new(genuineCoin: ICoin, wallet: Wallet) {
        const newCoin = new Coin()

        // Main genuine coin's fields
        newCoin.Act = genuineCoin.Main.Act
        newCoin.CbId = genuineCoin.Main.CbId
        newCoin.Cur = genuineCoin.Main.Cur
        newCoin.Exp = genuineCoin.Main.Exp
        newCoin.Id = genuineCoin.Main.Id
        newCoin.Issued = genuineCoin.Main.Issued
        newCoin.Tag = genuineCoin.Main.Tag
        newCoin.Val = genuineCoin.Main.Val
        newCoin.CryptoAlg = genuineCoin.Crypto.Alg
        newCoin.CryptoCentbSalt = genuineCoin.Crypto.CentB.Salt
        newCoin.CryptoCentbSign = genuineCoin.Crypto.CentB.Sign
        newCoin.CryptoIssSalt = genuineCoin.Crypto.Iss.Salt
        newCoin.CryptoIssSign = genuineCoin.Crypto.Iss.Sign
        newCoin.Chain = JSON.stringify(genuineCoin.Chain)
        // Additional DB coin's fields
        newCoin.ChainLastDate = genuineCoin.Chain[genuineCoin.Chain.length - 1].TransData.Date
        newCoin.ChainLastFrom = genuineCoin.Chain[genuineCoin.Chain.length - 1].TransData.From
        newCoin.ChainLastSign = genuineCoin.Chain[genuineCoin.Chain.length - 1].Sign
        newCoin.ChainLastTo = genuineCoin.Chain[genuineCoin.Chain.length - 1].TransData.To
        newCoin.ChainLinksAmount = genuineCoin.Chain.length
        newCoin.Checked = false
        newCoin.CheckDate = 0
        newCoin.CheckType = 0
        newCoin.CheckOnlineError = null
        newCoin.CheckResult = false
        newCoin.Wallet = wallet

        return newCoin
    }

    generateGenuineCoin(): ICoin {
        const genuineCoin: ICoin = {
            Main: {
                Val: this.Val,
                Id: this.Id,
                Issued: this.Issued,
                Exp: this.Exp,
                CbId: this.CbId,
                Cur: this.Cur,
                Act: this.Act,
                Tag: this.Tag
            },
            Crypto: {
                Alg: this.CryptoAlg,
                Iss: {
                    Sign: this.CryptoIssSign,
                    Salt: this.CryptoIssSalt
                },
                CentB: {
                    Sign: this.CryptoCentbSign,
                    Salt: this.CryptoCentbSalt
                }
            },
            Chain: JSON.parse(this.Chain)
        }
        return genuineCoin
    }

    moveToWallet(wallet: Wallet) {
        this.Wallet = wallet
    }

    check(checked: boolean, checkDate: number, checkType: number, checkResult: boolean, checkOnlineError?: number | null) {
        this.Checked = checked
        this.CheckDate = checkDate
        this.CheckType = checkType
        this.CheckResult = checkResult
        this.CheckOnlineError = (checkOnlineError) ? checkOnlineError : this.CheckOnlineError
    }
}
