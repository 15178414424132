import { IQrCodeWalletInfoPayload } from "@/interfaces/QrCode"
import { ETransferMode, ITransferLatetstReceivedTransactions } from "@/interfaces/Transfer"
import { ICoin } from "@/models/iCoin"
import { defineStore } from "pinia"

export const useTransferStore = defineStore({
    id: "transfer",
    state: () => ({
        mode: null as ETransferMode | null,
        // For online send
        receiver: {
            WalletId: "",
            WalletName: "",
            Summ: 0
        } as IQrCodeWalletInfoPayload,
        sender: {
            WalletId: "",
            WalletName: "",
            Summ: 0
        } as IQrCodeWalletInfoPayload,
        coinsIdsToSend: [] as string[],
        // For online receive
        requestedSumm: 0,
        latetstReceivedTransactions: {
            Amount: 0,
            Summ: 0,
            Currency: ""
        } as ITransferLatetstReceivedTransactions,
        isReceiving: false,
        // For offline usage
        coinsToSendOffline: [] as ICoin[],
        coinsIdsToReceiveOffline: [] as string[],
        // Outdated
        receivedCoinId: "",
        signedCoin: {} as ICoin | null
    }),
    getters: {
        getReceiverInfo: state => state.receiver,
        getSenderInfo: state => state.sender
    },
    actions: {
        async setTransferMode(queryMode: string) {
            switch (queryMode) {
                case "offline":
                    this.mode = ETransferMode.Offline
                    break
                case "online":
                    this.mode = ETransferMode.Online
                    break
                case "p2p":
                    this.mode = ETransferMode.P2P
                    break
                default:
                    break
            }
        },
        async setReceiverInfo(receiver: IQrCodeWalletInfoPayload) {
            this.receiver = receiver
        },
        async setSenderInfo(receiver: IQrCodeWalletInfoPayload) {
            this.sender = receiver
        },
        async clearReceiverInfo() {
            this.receiver = {
                WalletId: "",
                WalletName: "",
                Summ: 0
            }
        },
        async clearSenderInfo() {
            this.sender = {
                WalletId: "",
                WalletName: "",
                Summ: 0
            }
        },
        async setCoinsToSendOffline(coins: ICoin[]) {
            this.coinsToSendOffline = coins
        },
        async clearCoinsToSendOffline() {
            this.coinsToSendOffline = []
        },
        async setCoinsIdsToReceiveOffline(coinsIds: string[]) {
            this.coinsIdsToReceiveOffline = coinsIds
        },
        async clearCoinsIdsToReceiveOffline() {
            this.coinsIdsToReceiveOffline = []
        },
        async setRequestedSumm(summ: number) {
            this.requestedSumm = summ
        },
        async clearLatetstReceivedTransactions() {
            this.latetstReceivedTransactions = {
                Amount: 0,
                Summ: 0,
                Currency: ""
            }
        }
    }
})
