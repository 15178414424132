import { MigrationInterface, QueryRunner } from "typeorm"

export class CreateCoinTable1698585455046 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            CREATE TABLE "coins" (
                "Id"                TEXT PRIMARY KEY NOT NULL UNIQUE,
                "Act"	            BOOLEAN NOT NULL,
                "CbId"	            TEXT NOT NULL,
                "Cur"	            TEXT NOT NULL,
                "Exp"	            INTEGER NOT NULL,
                "Issued"	        INTEGER NOT NULL,
                "Tag"	            INTEGER NOT NULL,
                "Val"	            INTEGER NOT NULL,
                "CryptoAlg"	        TEXT NOT NULL,
                "CryptoCentbSalt"	TEXT NOT NULL,
                "CryptoCentbSign"	TEXT NOT NULL,
                "CryptoIssSalt"	    TEXT NOT NULL,
                "CryptoIssSign"	    TEXT NOT NULL,
                "Chain"	            TEXT NOT NULL,
                "ChainLastDate"	    INTEGER NOT NULL,
                "ChainLastFrom"	    TEXT,
                "ChainLastSign"	    TEXT,
                "ChainLastTo"	    TEXT NOT NULL,
                "ChainLinksAmount"	INTEGER NOT NULL,
                "Checked"	        BOOLEAN NOT NULL,
                "CheckDate"	        INTEGER NOT NULL,
                "CheckType"	        INTEGER NOT NULL,
                "CheckOnlineError"	INTEGER,
                "CheckResult"	    BOOLEAN NOT NULL,
                "Receipted"	    TEXT NOT NULL,
                "Updated"	    TEXT,
                "WalletId" TEXT NOT NULL,
                CONSTRAINT "FK_Coins_Wallets" FOREIGN KEY ("WalletId") REFERENCES "wallets" ("Id") ON DELETE NO ACTION ON UPDATE NO ACTION
            );
        `)
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            DROP TABLE "coins";
        `)
    }

}
