export enum ERpcRequestMethod {
    Payment = "payment"
}

export interface IRpcMethodPaymentParams {
    amount: number               // Сумма заказа к оплате в целочисленном формате, минимум 1
    currency: string             // Валюта заказа
}

export interface IRpcRequest {
    id: number | string
    replyUrl: string
    method: ERpcRequestMethod
    params: Record<string, any>
}

const mapping: Map<string, ERpcRequestMethod> = new Map(Object.values(ERpcRequestMethod).map(
    (memberValue) => [`${memberValue}`, memberValue] as const
))

export class RpcRequest implements IRpcRequest {
    readonly id: number | string
    readonly replyUrl: string
    readonly method: ERpcRequestMethod
    readonly params: Record<string, any>

    constructor(id: number | string, replyUrl: string, method: string, params: Record<string, any>) {
        let Method: ERpcRequestMethod
        if (typeof mapping.get(method) === "undefined")
            Method = ERpcRequestMethod.Payment
        else
            Method = <ERpcRequestMethod>mapping.get(method)

        this.id = id
        this.replyUrl = replyUrl
        this.method = Method
        this.params = params
    }
}

// --------------------------------

export enum ERpcMethodPaymentCurrency {
    ARS = "ARS"
}

export enum ERpcMethodPaymentResponseResult {
    Success = "success",
    Cancel = "cancel",
    Timeout = "timeout",
    Fail = "fail"
}

export interface IRpcMethodPaymentResponseResult {
    type: ERpcMethodPaymentResponseResult
    amount_requested: number
    amount_paid: number
    currency: ERpcMethodPaymentCurrency
    created: number
    reason: string
}

export interface IRpcResponse {
    id: number | string
    result: Record<string, any> | IRpcMethodPaymentResponseResult
}

// --------------------------------

export enum ERpcErrorCode {
    CantParse = -32700,
    InvalidRequest = -32600,
    MethodNotFound = -32601,
    InvalidParams = -32602,
    InternalError = -32603
}
// -32000 to - 32099	Server error	Reserved for implementation - defined server - errors.

interface Error {
    code: ERpcErrorCode
    message?: string
    data?: any
}

export interface IRpcError {
    id: number | string
    error: Error
}
