import { Base64 } from "js-base64"

export function useCodec() {

    class DeeplinkRpcCodec {
        encode(data: Record<string, any>): string {
            const stringPayload = JSON.stringify(data)
            const base64EncodedPayload = Base64.encode(stringPayload)
            return base64EncodedPayload
        }

        decode(data: string): Record<string, any> {
            const rawPayload = Base64.decode(data)
            return JSON.parse(rawPayload)
        }
    }

    return {
        DeeplinkRpcCodec
    }
}
