import { createI18n } from "vue-i18n"
import { getCurrentLanguage } from "@/utils/Helpers"
import enUS from "./locales/en.json"
import ruRU from "./locales/ru.json"
import esES from "./locales/es.json"
import arAr from "./locales/ar.json"

export default createI18n({
    legacy: false,
    locale: getCurrentLanguage() || process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    messages: {
        en: enUS,
        ru: ruRU,
        es: esES,
        ar: arAr
    }
})
