/* eslint-disable no-unused-vars */
import { BaseEntity, Column, Entity, OneToMany, PrimaryColumn } from "typeorm"
import { Coin } from "./coin"
import { EWalletType, IWallet } from "./iWallet"

@Entity("wallets")
export class Wallet extends BaseEntity implements IWallet {

    @PrimaryColumn({ unique: true, type: "text" })
        Id!: string

    @Column({ nullable: true, type: "text" })
        Name!: string

    @Column({ type: "text" })
        PublicKey!: string

    @Column({ type: "text" })
        EncPrivateKey!: string

    @Column({ type: "integer" })
        Type!: EWalletType

    @OneToMany(() => Coin, (coin) => coin.Wallet, {
        eager: true
    })
        Coins!: Coin[]

    static new(wallet: IWallet): Wallet {
        const newWallet = new Wallet()

        newWallet.Id = wallet.Id
        newWallet.Name = wallet.Name
        newWallet.PublicKey = wallet.PublicKey
        newWallet.EncPrivateKey = wallet.EncPrivateKey
        newWallet.Type = wallet.Type
        return newWallet
    }
}
