import { ERpcErrorCode, IRpcRequest, ERpcRequestMethod, IRpcMethodPaymentParams } from "../../interfaces/RPC"
import i18n from "@/i18n"

export function useJsonRpcParser() {

    interface IParserResult {
        result: boolean,
        reason?: ERpcErrorCode,
        message?: string
    }

    function checkMethodParams(method: ERpcRequestMethod, params: Record<string, any>): boolean {
        const paymentParams: IRpcMethodPaymentParams = params as IRpcMethodPaymentParams

        switch (method) {
            case ERpcRequestMethod.Payment:
                // Check for required parameters and their types
                if (!paymentParams.amount || typeof paymentParams.amount !== "number" || paymentParams.amount < 1)
                    return false
                else if (!paymentParams.currency || typeof paymentParams.currency !== "string" || paymentParams.currency.length < 1 || paymentParams.currency !== process.env.VUE_APP_WALLET_CURRENCY)
                    return false
                else
                    return true
            // Add more cases for other methods as needed
            default:
                // Throw an error if the method is not recognized
                return false
        }
    }

    class DeeplinkRpcParser{
        check(payload: IRpcRequest | any): IParserResult {
            // CantParse
            if (!payload)
                return { result: false, reason: ERpcErrorCode.CantParse, message: i18n.global.t("Composables.Deeplink.Message_1") }

            // InvalidRequest
            if (
                !(typeof payload === "object" && payload !== null)
                || !payload.id
                || !(typeof payload.id === "string" || typeof payload.id === "number")
                || !payload.replyUrl
                || !(typeof payload.replyUrl === "string")
                || !payload.method
                || !(typeof payload.method === "string")
            )
                return { result: false, reason: ERpcErrorCode.InvalidRequest, message: i18n.global.t("Composables.Deeplink.Message_2") }

            // MethodNotFound
            let methodsFound = false
            const methodsValues = Object.values(ERpcRequestMethod)
            methodsValues.forEach(method => {
                if (method === payload.method)
                    methodsFound = true
            })
            if (!methodsFound)
                return { result: false, reason: ERpcErrorCode.MethodNotFound, message: i18n.global.t("Composables.Deeplink.Message_3") }

            // InvalidParams
            if (!payload.params || !(typeof payload.params === "object") || !checkMethodParams(payload.method, payload.params))
                return { result: false, reason: ERpcErrorCode.InvalidParams, message: i18n.global.t("Composables.Deeplink.Message_4") }

            // OK
            return { result: true }
        }
    }

    return {
        DeeplinkRpcParser
    }
}
