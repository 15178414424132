import { createRouter, createWebHistory } from "@ionic/vue-router"
import { RouteRecordRaw, createWebHashHistory } from "vue-router"
import DefaultComponent from "../views/Default.vue"
import MainComponent from "../views/Main.vue"

const routes: Array<RouteRecordRaw> = [
    {
        name: "Default",
        path: "/",
        component: DefaultComponent
    },
    {
        name: "Welcome",
        path: "/welcome",
        component: () => import("@/views/Welcome.vue")
    },
    {
        name: "Main",
        path: "/main/",
        component: MainComponent,
        children: [
            {
                name: "Wallet",
                path: "wallet",
                component: () => import("@/views/Wallet/Index.vue")
            },
            {
                name: "Transfer",
                path: "transfer",
                component: () => import((process.env.VUE_APP_MODE === "cashier") ? "@/views/Transfer/Receive/MerchantPrepare.vue" : "@/views/Transfer/Index.vue")
            },
            {
                name: "History",
                path: "history",
                component: () => import("@/views/History/Index.vue")
            }
        ]
    },
    {
        name: "Wallet-Create",
        path: "/wallet/create",
        component: () => import("@/views/Wallet/Create.vue")
    },
    {
        name: "Wallet-Edit",
        path: "/wallet/edit",
        component: () => import("@/views/Wallet/Edit.vue")
    },
    {
        name: "Wallet-ChangePassword",
        path: "/wallet/password",
        component: () => import("@/views/Wallet/ChangePassword.vue")
    },
    {
        name: "Wallet-Deposit",
        path: "/wallet/deposit",
        component: () => import("@/views/Wallet/Deposit.vue")
    },
    {
        name: "Wallet-Export",
        path: "/wallet/export",
        component: () => import("@/views/Wallet/Export.vue")
    },
    {
        name: "Receive-Offline-Verify",
        path: "/receive/offline/verify",
        component: () => import("@/views/Transfer/Receive/OfflineVerify.vue")
    },
    {
        name: "Merchant-Prepare",
        path: "/merchant/prepare",
        component: () => import("@/views/Transfer/Receive/MerchantPrepare.vue"),
        beforeEnter: () => {
            if (!process.env.VUE_APP_MODE || process.env.VUE_APP_MODE !== "cashier")
                return false
        }
    },
    {
        name: "Merchant-Receive",
        path: "/merchant/receive",
        component: () => import("@/views/Transfer/Receive/MerchantReceive.vue"),
        beforeEnter: () => {
            if (!process.env.VUE_APP_MODE || process.env.VUE_APP_MODE !== "cashier")
                return false
        }
    },
    {
        name: "Embedded-Empty",
        path: "/merchant/embeddedempty",
        component: () => import("@/views/Transfer/Receive/EmbeddedEmpty.vue"),
        beforeEnter: () => {
            if (!process.env.VUE_APP_MODE || process.env.VUE_APP_MODE !== "cashier")
                return false
        }
    },
    {
        name: "Send-Offline-Prepare",
        path: "/send/offline/prepare",
        component: () => import("@/views/Transfer/Send/OfflinePrepare.vue")
    },
    {
        name: "Send-Offline-Transfer",
        path: "/send/offline/transfer",
        component: () => import("@/views/Transfer/Send/OfflineTransfer.vue")
    },
    {
        name: "Send-Online",
        path: "/send/online",
        component: () => import("@/views/Transfer/Send/Online.vue")
    },
    {
        name: "History-Details",
        path: "/history/:id",
        component: () => import("@/views/History/Details.vue")
    },
    {
        name: "P2P-Send",
        path: "/p2p/send",
        component: () => import("@/views/Transfer/P2P/Send.vue")
    },
    {
        name: "P2P-Receive",
        path: "/p2p/receive",
        component: () => import("@/views/Transfer/P2P/Receive.vue")
    },
    {
        name: "Chat",
        path: "/chat",
        component: () => import("@/views/Transfer/Chat.vue")
    },
    {
        name: "Buffer",
        path: "/buffer",
        component: () => import("@/views/Transfer/Buffer.vue")
    }
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes
})

export default router
