import { MigrationInterface, QueryRunner } from "typeorm"

export class CreateBankTable1698587754577 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            CREATE TABLE "banks" (
                "Id"	    INTEGER PRIMARY KEY NOT NULL UNIQUE,
                "Name"	    TEXT,
                "PublicKey"	TEXT NOT NULL,
                "WalletId"	TEXT NOT NULL,
                "Url"	    TEXT
            );
        `)
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`
            DROP TABLE "banks";
        `)
    }

}
