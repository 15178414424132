import { IRpcError, IRpcResponse, ERpcErrorCode } from "@/interfaces/RPC"

export function useJsonRpcBuilder() {

    class DeeplinkRpcBuilder {
        result(id: number | string, payload: Record<string, any>): IRpcResponse {
            return {
                id,
                result: payload
            }
        }

        error(id: number | string, reason: ERpcErrorCode, message?: string, payload?: any): IRpcError {
            return {
                id,
                error: {
                    code: reason,
                    ...(message && { message: message }),
                    ...(payload && { data: payload })
                }
            }
        }
    }

    return {
        DeeplinkRpcBuilder
    }
}
