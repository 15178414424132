import { ComponentInternalInstance } from "vue"
import MainDataSource from "../data-source"

export function useDB() {

    async function saveDbToWebStore(app: ComponentInternalInstance | null) {
        if (!app)
            return

        const platform = app?.appContext.config.globalProperties.$platform
        if (platform === "web") {
            const sqlite = app?.appContext.config.globalProperties.$sqlite
            const connection = MainDataSource
            const database = connection.options.database
            await sqlite.saveToStore(database)
        }
    }

    return {
        saveDbToWebStore
    }
}