import { useDB } from "@/composables/useDB"
import { ILatestWallet, ITransaction, Transaction } from "@/models/transaction"
import { defineStore } from "pinia"
import { ComponentInternalInstance } from "vue"
const { saveDbToWebStore } = useDB()

export const useTransactionStore = defineStore({
    id: "transaction",
    state: () => ({
        transactions: [] as Array<Transaction>
    }),
    getters: {
        getTransaction: (state) => {
            return (transactionId: number) => state.transactions.find((transaction) => transaction.Id === transactionId) as Transaction
        },
        getLatestTransactions: (state) => {
            return state.transactions
        },
        getLatestWallets: (state): ILatestWallet[] => {
            const latestWallets = state.transactions.map((transaction) => ({
                WalletId: transaction.WalletId,
                WalletName: transaction.WalletName
            })).filter((v,i,a) => a.findIndex(v2 => (v2.WalletId === v.WalletId)) === i)
            return latestWallets
        }
    },
    actions: {
        async fill() {
            try {
                const dbTransactions = await Transaction.find()
                this.transactions = dbTransactions.reverse()
            }
            catch (error) {
                console.log("Transaction store | Error in filling transaction store", error)
            }
        },
        async add(transaction: ITransaction, appInstance: ComponentInternalInstance) {
            try {
                const newTransaction = Transaction.new(transaction)
                this.transactions.unshift(newTransaction)
                await newTransaction.save()
                await saveDbToWebStore(appInstance)
            }
            catch (error) {
                console.log("Transaction store | Error in adding new transaction", error)
            }
        },
        async addMultipleTransactions(transactions: Array<ITransaction>, appInstance: ComponentInternalInstance) {
            try {
                const transactionsToBeAdded = transactions.map((transaction) => Transaction.new(transaction))
                this.transactions.unshift(...transactionsToBeAdded)

                // transactions.forEach(transaction => {
                //     const transactionToBeAdded = Transaction.new(transaction)
                //     transactionsToBeAdded.push(transactionToBeAdded)
                //     this.transactions.push(transactionToBeAdded)
                // })

                // Batch save coins to DB
                await Transaction.save(transactionsToBeAdded)
                await saveDbToWebStore(appInstance)
            }
            catch (error) {
                console.log(`Transaction store | ${error}`)
            }
        }
    }
})
