import { useCodec } from "@/composables/deeplink/useCodec"
import { useJsonRpcBuilder } from "@/composables/deeplink/useJsonRpcBuilder"
import { useJsonRpcParser } from "@/composables/deeplink/useJsonRpcParser"
import i18n from "@/i18n"
import { ERpcMethodPaymentResponseResult, ERpcRequestMethod, IRpcRequest, RpcRequest } from "@/interfaces/RPC"
import { useRpcStore } from "@/stores/rpc"
import { useTransferStore } from "@/stores/transfer"
import { NotificationIonic } from "@/utils/Notification"
import { AppLauncher } from "@capacitor/app-launcher"
import { Network } from "@capacitor/network"
import Router from "../router"

export function useDeeplink() {

    async function init(url: string) {
        const Notification = new NotificationIonic()
        const { DeeplinkRpcCodec } = useCodec()
        const { DeeplinkRpcParser } = useJsonRpcParser()
        const { DeeplinkRpcBuilder } = useJsonRpcBuilder()
        const rpcStore = useRpcStore()
        const codec = new DeeplinkRpcCodec
        const parser = new DeeplinkRpcParser
        const builder = new DeeplinkRpcBuilder

        console.log(`URL: ${url}`)

        const slug = url.split(".app").pop()?.trim()
        if (slug?.startsWith("/deeplink/")) {
            const dLinkPayload = slug.slice(10)

            const dLinkRequest = codec.decode(dLinkPayload)
            const parseResult = parser.check(dLinkRequest)
            console.log("Deeplink payload: ", dLinkRequest)
            console.log("Deeplink parse result: ", parseResult)

            if (parseResult.result) {
                const Request = new RpcRequest(dLinkRequest.id, dLinkRequest.replyUrl, dLinkRequest.method, dLinkRequest.params)
                await rpcStore.addRequest(Request)
            }
            else if (!parseResult.result && parseResult.reason && dLinkRequest.id && dLinkRequest.replyUrl && dLinkRequest.replyUrl.length > 0) {
                const result = builder.error(dLinkRequest.id, parseResult.reason, (parseResult.message) ? parseResult.message : undefined)
                const dLinkResult = codec.encode(result)
                callbackRequest(`${dLinkRequest.replyUrl}/${dLinkResult}`)
            }
            else {
                Notification.showNotification(i18n.global.t("Composables.UseDeeplink.Notifications.Note_1"), "danger")
            }
        }
    }

    async function proceedRequest(request: IRpcRequest) {
        const Notification = new NotificationIonic()
        const transferStore = useTransferStore()
        const networkConnectionStatus = await Network.getStatus()

        switch (request.method) {
            case ERpcRequestMethod.Payment:
                if (!networkConnectionStatus.connected) {
                    const datenow = Date.now() / 1000
                    const timestamp = parseInt(datenow.toFixed(0), 10)
                    proceedCallback(
                        {
                            type: ERpcMethodPaymentResponseResult.Fail,
                            amount_requested: request.params.amount,
                            amount_paid: 0,
                            currency: request.params.currency,
                            created: timestamp,
                            reason: i18n.global.t("Composables.UseDeeplink.Reason")
                        }
                    )
                }
                else {
                    await transferStore.setRequestedSumm(request.params.amount)
                    Router.replace({ name: "Merchant-Receive" })
                }
                break

            default:
                Notification.showNotification(i18n.global.t("Composables.UseDeeplink.Notifications.Note_2"), "warning")
                break
        }
    }

    async function proceedCallback(result: Record<string, any>) {
        const rpcStore = useRpcStore()
        const currentRequestId = rpcStore.currentRequestId

        if (!currentRequestId || currentRequestId === "") {
            console.warn("No current RPC request Id at this time")
            return
        }
        const currentRequest = await rpcStore.findRequest(currentRequestId)
        if (!currentRequest) {
            console.warn("Can't find current RPC request")
            return
        }

        try {
            const { DeeplinkRpcBuilder } = useJsonRpcBuilder()
            const { DeeplinkRpcCodec } = useCodec()
            const builder = new DeeplinkRpcBuilder
            const codec = new DeeplinkRpcCodec

            const callbackResult = builder.result(currentRequest.id, result)
            const encCallbackResult = codec.encode(callbackResult)

            // Remove RPC request from store
            await rpcStore.removeRequest(currentRequest.id)

            Router.replace({ name: "Embedded-Empty" })

            // Run callback to another App
            callbackRequest(`${currentRequest.replyUrl}/${encCallbackResult}`)
        }
        catch (error) {
            console.error(i18n.global.t("Composables.UseDeeplink.Errors.Error_1"), error)
        }
    }

    async function callbackRequest(url: string) {
        try {
            console.log("Deeplink callback url proceed: ", url)
            await AppLauncher.openUrl({ url })
        }
        catch (error) {
            console.error(i18n.global.t("Composables.UseDeeplink.Errors.Error_2"), error)
        }
    }

    return {
        init,
        proceedRequest,
        proceedCallback,
        callbackRequest
    }
}
