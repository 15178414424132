import { Bugfender } from "@bugfender/sdk"
import { App } from "@capacitor/app"
import { Device } from "@capacitor/device"
import { isPlatform } from "@ionic/vue"
import packageInfo from "../../package.json"

export function useBugfender() {

    async function bfInit() {
        const defaultAppInfo = {
            version: packageInfo.version,
            build: "unknown",
            id: "ru.goznak.digitalwalletapplocal"
        }

        try {
            const AppInfo = isPlatform("capacitor")
                ? await App.getInfo()
                : defaultAppInfo

            Bugfender.init({
                appKey: process.env.VUE_APP_BUGFENDER_KEY as string,
                build: AppInfo.build,
                debug: true,
                version: AppInfo.version
            })

            Bugfender.setDeviceKey("$package_id", AppInfo.id)

            if (isPlatform("capacitor")) {
                const info = await Device.getInfo()
                Bugfender.setDeviceKey("os", info.operatingSystem)
                Bugfender.setDeviceKey("os_version", info.osVersion)
                Bugfender.setDeviceKey("name", info.name as string)
                Bugfender.setDeviceKey("model", `${info.manufacturer} ${info.model}`)
                Bugfender.setDeviceKey("webview_version", info.webViewVersion)
            }

            Bugfender.log("Hello from Vue.js")

        }
        catch (error) {
            console.error(`Unable to init BugFender SDK: ${error}`)
            throw new Error("Unable to init BugFender SDK")
        }
    }

    function bfSetWalletData(walletId: string, walletName: string) {
        try {
            Bugfender.setDeviceKey("wallet_id", walletId)
            Bugfender.setDeviceKey("wallet_name", walletName)
        }
        catch (error) {
            console.error(error)
        }
    }

    return {
        bfInit,
        bfSetWalletData
    }
}