/* eslint-disable no-unused-vars */
import { BaseEntity, Column, Entity, PrimaryGeneratedColumn } from "typeorm"

export interface ILatestWallet {
    WalletId: string
    WalletName: string
}

export enum ETransactionType {
    Offline = 0,
    Online = 1,
    P2P = 2
}

export interface ITransaction {
    Amount: number
    CoinsIds: string[]
    CoinsVals: number[]
    Currency: string
    Date: number
    Incoming: boolean
    Type: ETransactionType
    WalletId: string
    WalletName: string
}

@Entity("transactions")
export class Transaction extends BaseEntity {

    @PrimaryGeneratedColumn()
        Id!: number

    @Column()
        Amount!: number

    @Column("simple-array")
        CoinsIds!: string[]

    @Column("simple-array")
        CoinsVals!: number[]

    @Column()
        Currency!: string

    @Column()
        Date!: number

    @Column()
        Incoming!: boolean

    @Column({ type: "integer" })
        Type!: ETransactionType

    @Column()
        WalletId!: string

    @Column({ nullable: true })
        WalletName!: string

    static new(transaction: ITransaction): Transaction {
        const newTransaction = new Transaction()

        newTransaction.Amount = transaction.Amount
        newTransaction.CoinsIds = transaction.CoinsIds
        newTransaction.CoinsVals = transaction.CoinsVals
        newTransaction.Currency = transaction.Currency
        newTransaction.Date = transaction.Date
        newTransaction.Incoming = transaction.Incoming
        newTransaction.Type = transaction.Type
        newTransaction.WalletId = transaction.WalletId
        newTransaction.WalletName = (transaction.WalletName) ? transaction.WalletName : ""

        return newTransaction
    }
}
