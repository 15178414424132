import { DataSource } from "typeorm"
import sqliteConnection from "./database"
import { CreateWalletTable1698582737472 } from "./migrations/1698582737472-CreateWalletTable"
import { CreateCoinTable1698585455046 } from "./migrations/1698585455046-CreateCoinTable"
import { CreateBankTable1698587754577 } from "./migrations/1698587754577-CreateBankTable"
import { CreateTransactionTable1698587772208 } from "./migrations/1698587772208-CreateTransactionTable"
import { Bank } from "./models/bank"
import { Coin } from "./models/coin"
import { Transaction } from "./models/transaction"
import { Wallet } from "./models/wallet"

export default new DataSource({
    name: "userConnection",
    type: "capacitor",
    driver: sqliteConnection,
    database: "db-main",
    entities: [
        Wallet,
        Coin,
        Transaction,
        Bank
    ],
    migrations: [
        CreateWalletTable1698582737472,
        CreateCoinTable1698585455046,
        CreateBankTable1698587754577,
        CreateTransactionTable1698587772208
    ],
    subscribers: [],
    logging: ["error", "query", "schema"],
    synchronize: false,
    migrationsRun: false
})
