import { BaseEntity, Column, Entity, PrimaryColumn } from "typeorm"

export interface IBank {
    Id: number
    Name: string
    PublicKey: string
    WalletId: string
    Url: string
}

@Entity("banks")
export class Bank extends BaseEntity implements IBank {

    @PrimaryColumn({ unique: true })
        Id!: number

    @Column({ nullable: true })
        Name!: string

    @Column()
        PublicKey!: string

    @Column()
        WalletId!: string

    @Column({ nullable: true })
        Url!: string

    static new(bank: IBank): Bank {
        const newBank = new Bank()

        newBank.Id = bank.Id
        newBank.Name = bank.Name
        newBank.PublicKey = bank.PublicKey
        newBank.WalletId = bank.WalletId
        newBank.Url = bank.Url
        return newBank
    }
}
