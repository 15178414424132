// Genuine coin structure
interface IcoinSign {
    Sign: string
    Salt: string
}
interface ICoinTransData {
    Nonce: number
    Date: number
    From: string
    To: string
}
interface ICoinMain {
    Val: number
    Id: string
    Issued: number
    Exp: number
    CbId: string
    Cur: string
    Act: boolean
    Tag: number
}
interface ICoinCrypto {
    Alg: string
    Iss: IcoinSign
    CentB: IcoinSign
}

interface ICoinChain {
    TransData: ICoinTransData
    Sign: string
    Salt: string
}

export interface ICoin {
    Main: ICoinMain
    Crypto: ICoinCrypto
    Chain: Array<ICoinChain>
}

export interface ICoinVal {
    id: string
    value: number
}

// Computable
export enum ECoinCheckType {
    Offline = 0,
    Online = 1
}
export enum ECoinCheckOnlineError {
    ChainValidation = 0,
    Resigning = 1,
    Other = 2
}
export enum ECoinCheckSign {
    Fail,
    NotChecked,
    OfflineOk,
    OnlineOk
}
